@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.our-team {
  background: linear-gradient(
    to bottom,
    white 97%,
    rgba(31, 28, 27, 0.16) 100%
  );

  &__image {
    width: 100vw;

    &.desktop {
      @media (max-width: 768px) {
        display: none;
      }
    }

    &.mobile {
      @media (min-width: 769px) {
        display: none;
      }
    }
  }

  &__separator {
    margin: vw(42px) 0 vw(10px) 0;
    border: none;
    border-top: 1px solid rgba(191, 191, 191, 1);

    @media (max-width: 768px) {
      margin: 4.6vw 0 5vw 0;
    }
  }

  &__title {
    font-family: "Times Now";
    width: 85%;
    text-align: left;
    margin: 0 0 vw(370px) vw(40px);

    @media (max-width: 768px) {
      width: 95%;
      margin: 0 0 32.4vw 1.8vw;
    }

    p {
      font-size: vw(64px);
      line-height: vw(66px);
      letter-spacing: 0.5px;
      margin-top: vw(40px);

      @media (max-width: 768px) {
        font-size: 6.4vw;
        letter-spacing: 1px;
        line-height: 6.8vw;
      }

      @media (max-width: 480px) {
        font-size: 6.361vw;
        letter-spacing: 1px;
        line-height: 7.634vw;
      }
    }
  }

  &__inspiration-words {
    width: 120vw;
    text-overflow: clip;
    text-align: left;
    justify-content: space-between;
    padding: 0;
    padding-bottom: vw(40px);
    margin: 0 -10%;
    white-space: nowrap;
    color: rgba(232, 232, 232, 1);
    line-height: vw(1.5px);
    position: relative;
    font-family: "PP Neue Montreal Book";
    font-weight: 500;

    @media (max-width: 768px) {
      margin: 0 -37%;
    }

    p {
      font-size: vw(50px);
      line-height: 0;

      &:nth-child(1) {
        @media (max-width: 480px) {
          margin-left: -27%;
        }
      }

      &:nth-child(2) {
        margin-left: -3%;

        @media (max-width: 768px) {
          margin-left: -5%;
        }

        @media (max-width: 480px) {
          margin-left: -33%;
        }
      }

      &:nth-child(3) {
        @media (max-width: 480px) {
          margin-left: -25%;
        }
      }

      @media (max-width: 768px) {
        font-size: 5vw;
        line-height: 0.5vw;
      }

      @media (max-width: 480px) {
        font-size: 6.9vw;
      }
    }
  }
}
