@import "../../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

$breakpoint-tablet: 768px;
$breakpoint-mobile: 480px;

.featured-card {
  display: flex;
  flex-direction: column;

  // Media container - handles overall sizing
  &__media {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // Size variants
    &--large {
      width: vw(680px);
      height: vw(720px);

      @media (max-width: $breakpoint-mobile) {
        width: 90vw;
        height: 75vw;
      }
    }

    &--medium {
      width: vw(1130px);
      height: vw(780px);

      @media (max-width: $breakpoint-tablet) {
        width: 95vw;
        height: 66vw;
      }

      @media (max-width: $breakpoint-mobile) {
        width: 90vw;
        height: 70vw;
      }
    }

    &--small {
      width: vw(1130px);
      height: vw(780px);

      @media (max-width: $breakpoint-tablet) {
        width: 95vw;
        height: 66vw;
      }

      @media (max-width: $breakpoint-mobile) {
        width: 90vw;
        height: 70vw;
      }
    }

    &--standard {
      width: vw(550px);
      height: vw(420px);

      @media (max-width: $breakpoint-tablet) {
        width: 46vw;
        height: 37vw;
      }

      @media (max-width: $breakpoint-mobile) {
        width: 90vw;
        height: 70vw;
      }
    }

    &--tall {
      width: vw(670px);
      height: vw(720px);

      @media (max-width: $breakpoint-tablet) {
        width: 46.354vw;
        height: 49.479vw;
      }

      @media (max-width: $breakpoint-mobile) {
        width: 90vw;
        height: 90vw;
      }
    }
  }

  // Background media
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  // Foreground media (placed on top of background)
  &__foreground {
    position: relative;
    z-index: 2;
    max-width: 80%;
    max-height: 80%;
    object-fit: contain;
  }

  // Single media content (no background)
  &__media-content {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }

  // Special case for card-booffer background styling
  &#card-noodles {
    .featured-card__foreground {
      max-height: unset;
    }

    .featured-card__foreground:nth-child(1) {
      border-radius: 20px;
      z-index: 2;
      height: vw(600px);

      @media (max-width: $breakpoint-mobile) {
        height: 60vw;
      }
    }

    .featured-card__foreground:nth-child(2) {
      position: absolute;
      z-index: 1;
      height: vw(626px);

      @media (max-width: $breakpoint-mobile) {
        height: 62.6vw;
      }
    }
  }

  // Special case for card-booffer styling
  &#card-booffer {
    .featured-card__foreground {
      width: vw(800px);
      height: vw(520px);
      margin: 0;

      @media (max-width: $breakpoint-tablet) {
        width: 75vw;
        height: unset;
      }

      @media (max-width: $breakpoint-mobile) {
        width: 88%;
        max-width: unset;
        height: unset;
      }

      border-radius: vw(10px);
    }

    .featured-card__media {
      @media (max-width: $breakpoint-mobile) {
        height: 90vw;
      }
    }
  }

  // Special case for card-sourcely styling
  &#card-sourcely {
    .featured-card__media {
      background-color: #c3cdf1;
    }

    .featured-card__media-content--wrapper {
      width: vw(784px);
      height: vw(480px);

      @media (max-width: $breakpoint-tablet) {
        width: 75vw;
        height: 45vw;
      }
    }

    .featured-card__media-content {
      width: 100%;
    }

    .featured-card__media-content:nth-child(1) {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      @media (max-width: $breakpoint-tablet) {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      @media (max-width: $breakpoint-mobile) {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }

    .featured-card__media-content:nth-child(2) {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      @media (max-width: $breakpoint-tablet) {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      @media (max-width: $breakpoint-mobile) {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  // Special case for card-parkwise styling
  &#card-parkwise {
    .featured-card__media {
      height: vw(480px);

      @media (max-width: $breakpoint-tablet) {
        height: 40vw;
      }

      @media (max-width: $breakpoint-mobile) {
        height: 78vw;
      }
    }
  }

  // Description section
  &__description {
    font-size: vw(20px);
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: $my-white;
    margin-top: vw(10px);

    @media (max-width: $breakpoint-tablet) {
      letter-spacing: 0.5px;
    }

    @media (max-width: $breakpoint-mobile) {
      width: 90vw;
    }

    // Description width variants
    &--wide p {
      width: 90%;

      @media (max-width: $breakpoint-tablet) {
        font-size: 2.1vw;
      }

      @media (max-width: $breakpoint-mobile) {
        width: 90%;
        font-size: 4.58vw;
      }
    }

    &--narrow p {
      width: 60%;

      @media (max-width: $breakpoint-tablet) {
        width: 70%;
        line-height: 3vw;
        font-size: 2.1vw;
      }

      @media (max-width: $breakpoint-mobile) {
        width: 90%;
        font-size: 4.58vw;
        line-height: 5.4vw;
      }
    }
  }

  // Arrow styling
  &__arrow {
    font-family: "PP Neue Montreal Book";
    font-size: vw(30px);
    margin: vw(14px);

    @media (max-width: $breakpoint-tablet) {
      font-size: 3vw;
    }

    @media (max-width: $breakpoint-mobile) {
      font-size: 6vw;
      margin-top: 3.3vw;
    }
  }
}
